import styled from 'styled-components'

const RecrutementContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    min-height: 700px;
    max-height: 700px; // fix cropped image in iframe

    & > iframe {
        width: 100%;
    }
`

export default RecrutementContainer
