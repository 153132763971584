import { PageProps } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import { LayoutProps } from '../../components/Layout.context'
import RecrutementContainer from '../../components/RecrutementContainer'

const RecrutementPage: React.FC<PageProps> = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={`Toutes nos offres d'emplois sont disponibles ici. CDI, CDD, Stage, consultez nos offres et rejoignez-nous l'aventure Save !`}
        />
      </Helmet>

      <RecrutementContainer>
        <iframe src="https://taleez.com/careers/save" />
      </RecrutementContainer>
    </>
  )
}

const layoutProps: LayoutProps = {
  title: 'Save recrute dans toute la France ! Consultez toutes nos offres',
  breadcrumb: [{ label: 'Recrutement' }],
}

export default Object.assign(RecrutementPage, {
  layoutProps,
})
